import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  // CardFooter,
  CardTitle,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import Navbar from "components/Navbars/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import DataSection from "components/Body/DataSection.js";
// import SectionCarousel from "views/index-sections/SectionCarousel.js";
import Slider from "components/Body/Slider.js";
import ReactPlayer from "react-player";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import AwesomeSliderOneYear from "assets/scss/customSliderOneYear.scss";
// import AwesomeSliderStyles from 'assets/scss/customSlider.scss';

const style = {
  color: { color: "#c61419" },
  textBold: { color: "#c61419", fontWeight: "bold" },
};

const slideData = [
  {
    index: 0,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522011807",
  },
  {
    index: 1,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522008875",
  },
  {
    index: 2,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522011453",
  },
  {
    index: 3,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522011609",
  },
  {
    index: 4,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522010946",
  },
  {
    index: 5,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522011115",
  },
  {
    index: 6,
    headline: "",
    button: "Assistir",
    src: "https://player.vimeo.com/video/522121327",
  },
];

function LandingPage() {
  const [showVideo, setShowVideo] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const playVideo = () => {
    setShowVideo(true);
    setProgress(false);
    setPlaying(true);
  };

  const oneYearVideo = () => {
    return (
      <Container
        style={
          !showVideo
            ? {
                backgroundImage:
                  "url(" + require("assets/img/up/rsz_capa_filtro.jpg") + ")",
                backgroundSize: "100% 100%",
              }
            : { backgroundColor: "white" }
        }
      >
        {!showVideo ? (
          <div>
            <div className="dark__header" style={{ height: "100%" }}></div>
            <div className="video__info">
              <Row>
                <Col md="12" xs="12" className="text-center">
                  <h4 style={{ color: "white" }}>
                    Assista nosso vídeo após 1 ano de operação, com um resumo de
                    todos os eventos legais criados através da plataforma.
                  </h4>
                  <br />
                  <Button
                    onClick={playVideo}
                    className="btn-round mr-1"
                    color="neutral"
                    target="_blank"
                    outline
                    id="play-video"
                  >
                    <i className="fa fa-play" />
                    Assistir video
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div id="video__header">
            <div className="video__show">
              <ReactPlayer
                url={"https://vimeo.com/522006616"}
                playing={playing}
                width="100%"
                height="100%"
                loop={true}
                controls={true}
                onPause={() => {
                  setShowVideo(false);
                  setProgress(false);
                  setPlaying(false);
                }}
                onProgress={(progress) => {
                  if (progress.played > 0) {
                    setProgress(true);
                  }
                }}
              />
            </div>
            {!progress ? <div className="dark__header"></div> : null}
          </div>
        )}
      </Container>
    );
  };

  return (
    <>
      <Navbar />
      <LandingPageHeader />

      <div className="main">
        <div className="section text-center">
          <Container>
            <br />
            <br />
            <Row className="mb-2">
              <Col md="6">
                <div className="info">
                  <div className="description">
                    <h4
                      className="info-title font-weight-bold mb-4"
                      style={{ marginTop: "18px" }}
                    >
                      Mais Barato
                      <img
                        className="ml-3"
                        width="60"
                        alt="..."
                        src={require("assets/img/up/cheap.png")}
                      />
                    </h4>
                    <p className="description">
                      Com altas taxas de agenciamento e valores não tão claros,
                      numa agência seu projeto custa uma fortuna, aqui para o
                      cliente <strong>É DE GRAÇA</strong>
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="info">
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      Mais Rápido
                      <img
                        className="ml-3"
                        width="35"
                        alt="..."
                        src={require("assets/img/up/fast.png")}
                      />
                    </h4>
                    <p>
                      Com Follows lotados, e muitos clientes importantes para
                      pouco time, seu job pode ficar sentado esperando em uma
                      longa fila, tornando a execução do seu projeto num
                      processo desnecessariamente lento, aqui você fala direto
                      com os seus fornecedores, sem telefone sem fio e com muita
                      agilidade.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="info">
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      Liberdade de Escolha
                      <img
                        className="ml-3"
                        width="40"
                        alt="..."
                        src={require("assets/img/up/freedom.png")}
                      />
                    </h4>
                    <p>
                      Uma agência sempre vai te oferecer trabalhar com os
                      fornecedores que forem melhores para ela, garantindo seus
                      próprios interesses. Aqui você escolhe o que é melhor para
                      você e sua empresa.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="info">
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      Mais Transparência
                      <img
                        className="ml-3"
                        width="35"
                        alt="..."
                        src={require("assets/img/up/transparency.png")}
                      />
                    </h4>
                    <p>
                      O fornecedor se conecta direto com o cliente dele de
                      verdade, não gastando energia, tempo e dinheiro com a
                      agência, que fica com grande parte do seu lucro, e com
                      muito poder sobre seu serviço. Além de evitar os erros e
                      dificuldades causados pelo famoso telefone sem fio gerado
                      pelo intermediário.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section section-red text-center">
          <Container>
            <br />
            <br />
            <Row className="mb-4">
              <Col md="12" className="mb-5">
                <Row>
                  <div>
                    <img
                      className="pull-left ml-4"
                      style={{ margin: "0px 30px 50px 30px" }}
                      width="90%"
                      alt="..."
                      src={require("assets/img/up/Icon_Titulo.png")}
                    />
                    <h3
                      className="font-weight-bold ml-4 mr-4"
                      style={{ color: "white" }}
                    >
                      Você pode estar pensando, "mas eu não tenho tempo de
                      cuidar e tocar um evento, a agência pelo menos cuida de
                      tudo para mim"
                    </h3>
                  </div>
                </Row>
              </Col>
              <Col md="6" className="mt-5">
                <div className="info">
                  <div className="icon icon-default">
                    <img
                      width="200"
                      alt="..."
                      src={require("assets/img/up/Icon_Criativos_Produtores.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      <img
                        className="mr-3"
                        width="14"
                        alt="..."
                        src={require("assets/img/up/Icon_Setas.png")}
                      />
                      CRIAÇÃO E PRODUÇÃO
                    </h4>
                    <p className="description">
                      Relaxa, quem cuida de tudo para você é normalmente uma
                      dupla de criação e um produtor, contratados pela agência e
                      superfaturados para você. Aqui você contrata esses
                      profissionais direto, pagando menos e eles recebendo mais,
                      imagina quanto carinho e dedicação irão dar para o seu
                      evento :)
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="info mt-5">
                  <div className="icon icon-default">
                    <img
                      width="152"
                      alt="..."
                      src={require("assets/img/up/Icon_Dados.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      <img
                        className="mr-3"
                        width="14"
                        alt="..."
                        src={require("assets/img/up/Icon_Setas.png")}
                      />
                      FERRAMENTAS E DADOS
                    </h4>
                    <p>
                      E você ainda tem uma série de ferramentas e dados que
                      deixam o seu evento, e a comunicação dos setores da sua
                      empresa muito mais rápidos, simples e eficazes.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md="6">
                <div className="info">
                  <div className="icon icon-default">
                    <img
                      width="150"
                      alt="..."
                      src={require("assets/img/up/Icon_Planilha.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      <img
                        className="mr-3"
                        width="14"
                        alt="..."
                        src={require("assets/img/up/Icon_Setas.png")}
                      />
                      PLANILHA
                    </h4>
                    <p>
                      Seu job terá uma planilha com diversas ferramentas, como a
                      de comparação automática de preços entre fornecedores,
                      controle da porcentagem gasta do budget que você destinou,
                      armazenamento de notas fiscais, dados e pagamentos e muito
                      mais, tudo para agilizar e deixar tudo muito mais fácil e
                      eficiente.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="info mt-3">
                  <div className="icon icon-default">
                    <img
                      width="109"
                      alt="..."
                      src={require("assets/img/up/Icon_Fornecedores.png")}
                    />
                  </div>
                  <div className="description">
                    <h4 className="info-title font-weight-bold mb-4">
                      <img
                        className="mr-3"
                        width="14"
                        alt="..."
                        src={require("assets/img/up/Icon_Setas.png")}
                      />
                      FORNECEDORES
                    </h4>
                    <p>
                      Você tem contato direto com os fornecedores, mas se tiver
                      sem tempo o produtor cuida de tudo para você, enquanto
                      você acompanha se quiser todos os bate papos e tramites
                      entre eles, apenas aceitando os orçamentos com um clique.
                      Contando com uma super seleção selecionada e treinada pelo
                      nosso time da UpSampa.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="section text-center"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <AwesomeSlider
            organicArrows={false}
            bullets={false}
            animation="cubeAnimation"
            cssModule={AwesomeSliderOneYear}
          >
            {oneYearVideo()}
          </AwesomeSlider>
        </div>

        <div className="section text-center">
          <Container>
            <br />
            <br />
            <Row>
              <Col md="12" className="mb-5">
                <h2 style={style.textBold}>
                  CONFIRA OS NOSSOS NÚMEROS:
                  <img
                    alt="..."
                    className="ml-2"
                    height="25"
                    src={require("assets/img/up/Icon_setas_2.png")}
                  ></img>
                </h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="3" className="mb-2">
                <div className="info border-6 h-100 rounded shadow">
                  <DataSection number="237"></DataSection>
                  <h3 style={style.color}>
                    <strong>PROJETOS EXECUTADOS</strong>
                  </h3>
                </div>
              </Col>
              <Col md="3" className="mb-2">
                <div className="info border-6 h-100 rounded shadow">
                  <DataSection number="10"></DataSection>
                  <h3 style={style.color}>
                    <strong>ESTADOS DO BRASIL</strong>
                  </h3>
                </div>
              </Col>
              <Col md="3" className="mb-2">
                <div className="info border-6 h-100 rounded shadow">
                  <DataSection suffix="%" number="92"></DataSection>
                  <h3 style={style.color}>
                    <strong>PROJETOS COTADOS, EXECUTADOS</strong>
                  </h3>
                </div>
              </Col>
              <Col md="3" className="mb-2">
                <div className="info border-6 h-100 rounded shadow">
                  <div
                    style={{
                      fontSize: "2.825em",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DataSection
                      prefix="R$"
                      decimals="1"
                      number="16"
                    ></DataSection>
                    <strong
                      style={{
                        paddingLeft: 5,
                        color: "#c61419",
                        fontWeight: "bold",
                      }}
                    >
                      {` `}MI
                    </strong>
                  </div>

                  <h3 style={{ fontWeight: "bolder", color: "#c61419" }}>
                    {" "}
                    RECEBIDOS POR FORNECEDORES CADASTRADOS
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div>
          <Slider heading="Example Slider" slides={slideData}/>
        </div> */}

        <div
          className="section section-red text-center"
          style={{ overflowX: "hidden" }}
        >
          <Container>
            <Row className="mb-5">
              <Col md="1"></Col>
              <Col md="10">
                <h2 style={{ color: "white", fontWeight: "bold" }}>
                  Curtam com a gente alguns dos momentos que já rolaram, e as
                  atuações primorosas da nossa popstar Vovó Ana
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                {/* <SectionCarousel color="#c61419" /> */}
                <Slider heading="" slides={slideData} />
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className="section text-center">
          <Container>
          <h2 className="title">Se liga no que quem usa a plataforma está achando dela:</h2>
          <Row>
            <Col md="12">
            </Col>
          </Row>
          </Container>
        </div> */}
        <div className="section section-dark text-center">
          <Container>
            <Row>
              <Col md="3"></Col>
              <Col md="6">
                <h2 className="title">
                  Se liga no que quem usa a plataforma está achando dela:
                </h2>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={require("assets/img/faces/ju.jpg")} />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Juliana Gubel</CardTitle>
                        <h6 className="card-category">
                          Gerente de Marketing Philips Lumileds
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      "O Toti conseguiu entender o que incomodava a gente em
                      relação as agências de publicidade e Agências de eventos.
                      Eu acho a ideia incrível e nós somos parceiros."
                    </p>
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    <h3 style={{color: 'white', fontWeight: 'bold'}}>Luis Rohten</h3>
                    <h6 style={{color: 'white'}}>Gerente de Marketing Philips Lumileds</h6>
                  </CardFooter> */}
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/perez.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Alexandre Perez</CardTitle>
                        <h6 className="card-category">
                          Dir. Geral da Fábrica OralB e Gillette Manaus
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      "Fizemos uma convenção para nossa fábrica como nunca visto
                      antes! Tivemos a melhor aprovação dos funcionários da
                      história, e ainda gastamos 42% para produzir do que o
                      valor que tínhamos com a agência, agora você me diz se
                      vale a pena!"
                    </p>
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter> */}
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/faces/vovoana.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Vovó Ana</CardTitle>
                        <h6 className="card-category">
                          Garota Propaganda da UpSampa
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      "Meu filho, eu amo trabalhar na UpSampa, ainda mais que
                      pagam parte do meu salário em Big Mac."
                    </p>
                  </CardBody>
                  {/* <CardFooter className="text-center">
                    <Button
                      className="btn-just-icon btn-neutral"
                      color="link"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-google-plus" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div> */}
      </div>
      <DemoFooter />
    </>
  );
}

export default LandingPage;
