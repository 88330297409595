import React from "react";
import ReactPlayer from 'react-player';

// =========================
// Slide
// =========================

/*const iframeConfig = {
    container: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '56.25%'
    },
    responsiveIframe: {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100%',
        height: '100%'
    },
    containerVimeo: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
    },
}*/

class Slide extends React.Component {
    constructor(props) {
        super(props)

        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        this.handleSlideClick = this.handleSlideClick.bind(this)
        this.imageLoaded = this.imageLoaded.bind(this)
        this.slide = React.createRef()

        this.state = {
            playing: false,
            showPause: false
        }
    }

    playPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleMouseMove(event) {
        const el = this.slide.current
        const r = el.getBoundingClientRect()

        el.style.setProperty('--x', event.clientX - (r.left + Math.floor(r.width / 2)))
        el.style.setProperty('--y', event.clientY - (r.top + Math.floor(r.height / 2)))
    }

    handleMouseLeave(event) {
        this.slide.current.style.setProperty('--x', 0)
        this.slide.current.style.setProperty('--y', 0)
    }

    handleSlideClick(event) {
        this.props.handleSlideClick(this.props.slide.index)
    }

    imageLoaded(event) {
        event.target.style.opacity = 1
    }

    render() {
        const { src, button, headline, index } = this.props.slide
        const current = this.props.current
        let classNames = 'slide'
        const { playing, showPause } = this.state;

        if (current === index) classNames += ' slide--current'
        else if (current - 1 === index) classNames += ' slide--previous'
        else if (current + 1 === index) classNames += ' slide--next'

        return (
            <li
                ref={this.slide}
                className={classNames}
                onClick={this.handleSlideClick}
                onMouseMove={this.handleMouseMove}
                onMouseLeave={this.handleMouseLeave}
            >
                <div className="slide__image-wrapper">
                    <ReactPlayer
                        url={src}
                        width="100%"
                        height="100%"
                        playing={playing}
                        onPlay={() => {
                            this.setState({ showPause: true })
                            console.log('Play!')
                        }}
                        onStart={() => {
                            this.setState({ showPause: true })
                            console.log('Start!')
                        }}
                        onPause={() => {
                            this.setState({ showPause: false })
                            console.log('Pause!')
                        }}
                        onBuffer={() => console.log('onBuffer')}
                        onEnded={() => { 
                            this.setState({ playing: false })
                        }}
                        onError={e => console.log('onError', e)}
                    />


                </div>
                <div className="slide__image-wrapper" style={{ opacity: '0' }}>
                    <div className="overlay"></div>
                </div>

                {/* <article className="slide__content">
                    {!playing ? <h2 className="slide__headline">{headline}</h2> : ''}
                    {!playing ? <button className="slide__action btn" onClick={this.playPause}>Assistir</button> : null}

                </article> */}

                <div className="slide__container">
                    <article className="slide__content">
                        {!playing ? <h2 className="slide__headline">{headline}</h2> : ''}
                        {!playing ? <button className="slide__action btn" onClick={this.playPause}>Assistir</button> : null}
                        {!showPause && playing ?
                            <img width="60" height="60" className="slide__loading" src={require("assets/img/loading/loading.gif")} alt="..." />
                            :
                            null
                        }
                        {showPause ?
                            <button onClick={this.playPause} 
                            style={{ backgroundColor: 'transparent',
                                    backgroundRepeat:'no-repeat',
                                    border: 'none',
                                    cursor:'pointer',
                                    overflow: 'hidden',
                                    outline:'none',
                                    zIndex: '4'
                            }}>
                                <img width="60" height="60" src={require("assets/img/up/close.svg")} alt="..." style={{ position: 'absolute', top: '11px', right: '0px' }} />
                            </button>
                        :
                            null
                    }
                    </article>

                    
                </div>
            </li>
        )
    }
}


// =========================
// Slider control
// =========================

const SliderControl = ({ type, title, handleClick }) => {
    return (
        <button className={`btn btn--${type}`} title={title} onClick={handleClick}>
            <svg className="icon" viewBox="0 0 24 24">
                <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
        </button>
    )
}


// =========================
// Slider
// =========================

class Slider extends React.Component {
    constructor(props) {
        super(props)

        this.state = { current: 1 }
        this.handlePreviousClick = this.handlePreviousClick.bind(this)
        this.handleNextClick = this.handleNextClick.bind(this)
        this.handleSlideClick = this.handleSlideClick.bind(this)
    }

    handlePreviousClick() {
        const previous = this.state.current - 1

        this.setState({
            current: (previous < 0)
                ? this.props.slides.length - 1
                : previous
        })
    }

    handleNextClick() {
        const next = this.state.current + 1;

        this.setState({
            current: (next === this.props.slides.length)
                ? 0
                : next
        })
    }

    handleSlideClick(index) {
        if (this.state.current !== index) {
            this.setState({
                current: index
            })
        }
    }

    render() {
        const { current/*, direction*/ } = this.state
        const { slides, heading } = this.props
        const headingId = `slider-heading__${heading.replace(/\s+/g, '-').toLowerCase()}`
        const wrapperTransform = {
            'transform': `translateX(-${current * (100 / slides.length)}%)`,
            'paddingLeft': '0px'
        }

        return (
            <div className='slider' aria-labelledby={headingId}>
                <ul className="slider__wrapper" style={wrapperTransform}>
                    <h3 id={headingId} className="visuallyhidden">{heading}</h3>

                    {slides.map(slide => {
                        return (
                            <Slide
                                key={slide.index}
                                slide={slide}
                                current={current}
                                handleSlideClick={this.handleSlideClick}
                            />
                        )
                    })}
                </ul>

                <div className="slider__controls">
                    <SliderControl
                        type="previous"
                        title="Vídeo Anterior"
                        handleClick={this.handlePreviousClick}
                    />

                    <SliderControl
                        type="next"
                        title="Próximo vídeo"
                        handleClick={this.handleNextClick}
                    />
                </div>
            </div>
        )
    }
}


export default Slider;