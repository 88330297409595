import React, {Component} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const component = {
    style: {
        countup: {fontSize: '40px', color: '#c61419', fontWeight: 'bold'},
    },
    classes: {
        className: {},
        col: {},
    },
    componentName: {}
}

class DataSection extends Component {
    state = {
        didViewCountUp: false
      };
    
    
      onVisibilityChange = isVisible => {
        if (isVisible) {
          this.setState({didViewCountUp: true});
        }
      }
    
      render() {
        return (
          <div className={component.componentName}>
            <VisibilitySensor onChange={this.onVisibilityChange} offset={{
              top:
                10
            }} delayedCall>
              <CountUp  className={component.classes.className} 
                        style={component.style.countup} 
                        start={0} 
                        end={this.state.didViewCountUp ? parseFloat(this.props.number) : 0}
                        duration={2} 
                        decimals={parseInt(this.props.decimals) ? parseInt(this.props.decimals) : 0} 
                        suffix={this.props.suffix ? this.props.suffix : ''} 
                        prefix={this.props.prefix ? this.props.prefix : ''} 
                       />
            </VisibilitySensor>
          </div>)
      }
}

export default DataSection;