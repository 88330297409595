
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import AwesomeSlider from 'react-awesome-slider';
import ReactPlayer from 'react-player';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import AwesomeSliderApresentation from 'assets/scss/customSliderApresentation.scss';

// import { isMobile } from 'react-device-detect';

// core components
class LandingPageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.playVideo = this.playVideo.bind(this);
    this.state = {
      showVideo: false,
      progress: false,
      playing: false
    }
  }

  playVideo = () => {
    this.setState({ showVideo: true, progress: false, playing: true })
  }

  changeMuted = () => {
    return !this.state.showVideo;
  }

  apresentationVideo = () => {
    return (
      <Container style={{ backgroundColor: 'white' }}>
        {!this.state.showVideo ?
          //exibe o vídeo ilustrativo da plataforma
          <div id="video__header">

            {this.state.progress ?
              <div className="video__info">
                <Row>
                  <Col md="12" xs="12" className="text-center" >
                    <h4 style={{ color: 'white' }}>A plataforma onde você cria, planeja e produz seu evento sem a necessidade de uma agência.</h4>
                    <br />
                    <Button
                      onClick={this.playVideo}
                      className="btn-round mr-1"
                      color="neutral"
                      target="_blank"
                      outline
                      id="play-video"
                    >
                      <i className="fa fa-play" />
                        Assistir video
                      </Button>
                  </Col>
                </Row>
              </div>
              :
              null
            }

            <div className="video__show">
              <ReactPlayer
                url={'https://vimeo.com/522006791'}
                playing={true}
                width='100%'
                height='100%'
                muted
                loop={true}
                onProgress={(progress) => {
                  if (progress.played > 0) {
                    this.setState({ progress: true })
                  }
                }}
              />
            </div>

            {!this.state.progress ?
              <img width="60" height="60" style={{ marginBottom: '200px' }} className="slide__loading" src={require("assets/img/loading/loading.gif")} alt="..." />
              :
              null
            }

            <div className="dark__header"></div>
          </div> :
          //exibe o vídeo
          <div id="video__header">
            <div className='video__show'>

              <ReactPlayer
                url={'https://vimeo.com/522006714'}
                playing={true}
                width='100%'
                height='100%'
                loop={true}
                controls={true}
                onPause={() => {
                  this.setState({ showVideo: false, progress: false });
                }}
                onProgress={(progress) => {
                  if (progress.played > 0) {
                    this.setState({ progress: true });
                  }
                }}
              />
            </div>
            {!this.state.progress ?
              <div className="dark__header"></div>
              :
              null
            }

          </div>

        }
      </Container>
    )
  }

  render = () => {
    return (
      <>
        {/* {!isMobile ? */}
          <div>
            <AwesomeSlider organicArrows={false} bullet={false} animation="cubeAnimation" cssModule={AwesomeSliderApresentation}>
              {this.apresentationVideo()}
            </AwesomeSlider>
          </div>
          {/* :
          <div
            className="page-header"
            data-parallax={true}
          // ref={pageHeader}
          >
            <video width="auto" height="100%" autoPlay muted loop id="myVideo">
              <source src={require("assets/videos/upsampa_short.mp4")} type="video/mp4" />
            </video>
            <div className="filter" />
            <Container style={{ position: 'absolute' }}>
              <div className="motto text-center">
                <Row>
                  <Col md="2"></Col>
                  <Col md="8">
                    <h3>A plataforma onde você cria, planeja e produz seu evento sem a necessidade de uma agência.</h3>
                    <br />
                    <Button
                      href="https://www.youtube.com/watch?v=vJuNUDOd170"
                      className="btn-round mr-1"
                      color="neutral"
                      target="_blank"
                      outline
                    >
                      <i className="fa fa-play" />
              Assistir video
                  </Button>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        } */}
      </>
    );
  }
}

export default LandingPageHeader;
