
import React from "react";
import { Link } from "react-router-dom";
// import axios from "axios";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // InputGroup,
  // InputGroupAddon,
  // InputGroupText,
  // Input,
  // Form,
  // FormGroup,
  // Button
} from "reactstrap";

function ExamplesNavbar() {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const toggle = () => setDropdownOpen(prevState => !prevState);

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  // function handleSubmit(event) {
  //   event.preventDefault();
  //   const { email, password } = event.target;

  //   axios.post(`http://hom.upsampa.com/backend/makeLogin`,
  //     {
  //       email: email.value,
  //       password: password.value
  //     }
  //   ).then(res => {
  //     const result = res.data;
  //     console.log(result)
  //   }).catch(error => {
  //     console.log(error)
  //   })
  //   console.log(email.value);
  //   console.log(password.value);

  // }

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/index"
            target="_blank"
            title="UpSampa"
            tag={Link}
          >

            <img width="150" alt="..." src={require("assets/img/up/UpSampa_branco.png")} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink href="http://web.upsampa.com/login" style={{
                color: 'white', background: '#E9242A',
                borderRadius: '3px',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
                paddingRight: '5px',
                borderBottomWidth: '0px',
                marginTop: '25px',
              }}/*to="/index" tag={Link}*/>
                <i className="nc-icon nc-layout-11" /> Entrar
                {/* <Dropdown isOpen={dropdownOpen} size="sm" toggle={toggle}>
                  <DropdownToggle style={{
                    borderTopWidth: '0px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    borderBottomWidth: '0px',
                    marginTop: '0px',
                    marginBottom: '0px'
                  }} caret>
                    <i className="nc-icon nc-layout-11" /> Entrar
                  </DropdownToggle>
                  <DropdownMenu right style={{ width: '350px' }}>
                    <DropdownItem style={{ color: '#E9242A' }} header>Login - Plataforma UpSampa</DropdownItem>
                    <Form onSubmit={(event) => handleSubmit(event)}>
                      <FormGroup style={{ marginBottom: '0px' }}>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="enter-home">Usuário</InputGroupText>
                          </InputGroupAddon>
                          <Input type="email" name="email" placeholder="Usuário" />
                        </InputGroup>
                        <InputGroup >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ paddingRight: '27px' }} className="enter-home">Senha</InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="password" placeholder="Senha" />
                        </InputGroup>
                        <div className="row justify-content-center">
                          <Button type="submit">Entrar</Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </DropdownMenu>
                </Dropdown> */}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="http://web.upsampa.com/cadastro/" style={{
                color: 'white', background: '#E9242A',
                borderRadius: '3px',
                paddingTop: '5px',
                paddingBottom: '5px',
                paddingLeft: '5px',
                paddingRight: '5px',
                borderBottomWidth: '0px',
                marginTop: '25px',
              }}
              >
                <i className="nc-icon nc-book-bookmark" />
                Cadastre-se
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar >
  );
}

export default ExamplesNavbar;
